import { Col } from 'components/grid'
import { Link } from 'gatsby'
import React from 'react'
import { Post } from 'templates/news'

interface Props {
	data: Post
}

export default function ArticleCard({ data }: Props) {
	return (
		<Col width="w-full">
			<div>
				<div className="px-3 pb-8">
					<Link to={'/news/' + data.slug} className="!no-underline">
						<h5 className="font-normal">{data.title}</h5>
					</Link>
					<div className=" mt-1 flex items-center justify-start">
						<span className=" flex text-sm text-slate-400">{data.publishedDate}</span>
						<span className="mx-2 block text-sm text-slate-400"> | </span>
						<span className="block  max-w-max  text-sm text-ar-100">{data.category === 'Article' ? 'In the News' : data.category}</span>
					</div>
				</div>
			</div>
		</Col>
	)
}
